.AdditionsArea {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;

  height: 100% !important;
}
.AdditionsConteiner {
  background-color: white;
  height: 100% !important;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.confirmation {
  width: 100%;
  height: 100%;
  background-color: #4eb2a1;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: fadein 1.2s ease-in; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.2s ease-in; /* Firefox < 16 */
  -ms-animation: fadein 1.2s ease-in; /* Internet Explorer */
  -o-animation: fadein 1.2s ease-in; /* Opera < 12.1 */
  animation: fadein 1.2s ease-in;
}
.confirmation2 {
  width: 100%;
  height: 100%;
  background-color: #4eb2a1;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 0px 10px 10px 0px;
}
.confirmation3 {
  width: 100%;
  height: 100%;
  background-color: #4eb2a1;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 10px #0000001a;
}
.visible {
  visibility: visible;
  -webkit-animation: fadein 1.2s ease-in; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.2s ease-in; /* Firefox < 16 */
  -ms-animation: fadein 1.2s ease-in; /* Internet Explorer */
  -o-animation: fadein 1.2s ease-in; /* Opera < 12.1 */
  animation: fadein 1.2s ease-in;
}
.hidden {
  visibility: hidden;
}
#exit {
  width: 25%;
  cursor: pointer;
}
.ItemBox {
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 0px 10px 10px 0px;
  min-height: 180px !important;
}
.ItemBox2 {
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 0px 10px 10px 0px;
  min-height: 120px !important;
}
.chosenMealArea {
  position: fixed !important;
  bottom: 0;
  left: 0;
}
.chosenMeal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -2px 10px #0000000d;
}
.add-regular {
  width: 60%;
  border-radius: 10px !important;
}
.add-regular:hover {
  width: 60%;
  border: 1px solid #dcae00;
}
.add-regular-additions {
  width: 60%;
  border-radius: 10px !important;
}
.add-regular-additions:hover {
  width: 60%;
  border: 1px solid #dcae00;
}
.h1001 {
  min-height: 33.33% !important;
}
.h1002 {
  min-height: 33.33% !important;
}
.h1003 {
  min-height: 33.33% !important;
}
.img-item {
  background-size: auto 100%;
  background-repeat: no-repeat;
}
#a {
  width: 20px;
}
.f-size-title {
  font-size: 1.4rem !important;
  text-transform: uppercase;
}
.f-size-disc {
  font-size: 1rem !important;
}
.OptHeight {
  overflow: auto;
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .add-regular-additions {
    width: 80%;
    border-radius: 10px !important;
  }
  .add-regular-additions:hover {
    width: 80%;
    border: 1px solid #dcae00;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .f-size {
    font-size: 0.7rem !important;
  }
  .f-size-button {
    font-size: 0.9rem !important;
  }
  .f-size-title {
    font-size: 1rem !important;
  }
  .f-size-disc {
    font-size: 0.8rem !important;
  }
  .ItemBox {
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 0px 10px 10px 0px;
    min-height: 130px !important;
  }
  .ItemBox2 {
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 0px 10px 10px 0px;
    min-height: 110px !important;
  }
  .add-regular-additions {
    width: 100%;
  }
  .add-regular-additions:hover {
    width: 100%;
  }
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  #exit {
    width: 50%;
    cursor: pointer;
  }
  .f-size {
    font-size: 0.7rem !important;
  }
  .f-size-button {
    font-size: 0.9rem !important;
  }
  .f-size-disc {
    font-size: 0.75rem !important;
  }
  .f-size-title {
    font-size: 1rem !important;
  }
  .add-regular {
    width: 100%;
  }
  .add-regular:hover {
    width: 100%;
  }
  .add-regular-additions {
    width: 100%;
  }
  .add-regular-additions:hover {
    width: 100%;
  }
  .ItemBox {
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 0px 10px 10px 0px;
    min-height: 130px !important;
  }
  .ItemBox2 {
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 0px 10px 10px 0px;
    min-height: 100px !important;
  }
}
@media screen and (max-width: 570px) {
  .h3-custom {
    font-size: 1rem;
  }
  .f-size {
    font-size: 0.7rem !important;
  }
  .f-size-button {
    font-size: 0.85rem !important;
  }
  .f-size-disc {
    font-size: 0.65rem !important;
  }
  .f-size-title {
    font-size: 1.05rem !important;
    line-height: 1rem !important;
  }
  .add-regular {
    width: 100%;
  }
  .add-regular:hover {
    width: 100%;
  }
  .add-regular-additions {
    width: 100%;
  }
  .add-regular-additions:hover {
    width: 100%;
  }
  .h1001 {
    min-height: 25% !important;
  }
  .h1002 {
    min-height: 25% !important;
  }
  .h1003 {
    min-height: 50% !important;
  }
  .ItemBox {
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 0px 10px 10px 0px;
    min-height: 100px !important;
  }
  .ItemBox2 {
    box-shadow: 0px 0px 10px #0000001a;
    border-radius: 0px 10px 10px 0px;
    min-height: 70px !important;
  }
  #exit {
    width: 70%;
    cursor: pointer;
  }
}
