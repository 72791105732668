button:active,
button:focus,
a:focus,
button:focus {
  -ms-outline: none !important;
  -o-outline: none !important;
  -moz-outline: none !important;
  outline: none !important; /* CSS 3 */
}
button:active,
button:focus {
  -moz-outline: none !important;
  outline: none !important; /* CSS 3 */
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
}
@media screen and (min-width: 570px) and (max-width: 768px) {
}
@media screen and (max-width: 570px) {
}
