.footer {
  background-color: white;
}
.size-1 {
  font-size: 0.7rem;
}
.c-white {
  color: white;
}
#ah {
  color: black !important;
}
.fw-footer {
  background: transparent linear-gradient(180deg, #dcae00 0%, #dc8b00 100%) 0%
    0% no-repeat padding-box;
}
#logo-footer {
  width: 46%;
  margin-bottom: auto;
  margin-top: auto;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cart {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .cart {
    position: fixed;
    bottom: 20px;
    left: 5px;
  }
  #logo-footer {
    width: 55%;
    margin-bottom: auto;
    margin-top: auto;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  #logo-footer {
    width: 68%;
    margin-bottom: auto;
    margin-top: auto;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .cart {
    position: fixed;
    bottom: 20px;
    left: 5px;
  }
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  #logo-footer {
    margin-bottom: auto;
    margin-top: auto;
    width: 30%;
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  .cart {
    position: fixed;
    bottom: 20px;
    left: 5px;
  }
}
@media screen and (max-width: 570px) {
  #logo-footer {
    margin-bottom: auto;
    width: 50%;
    margin-top: auto;
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  .footer-font1 {
    font-size: 0.9rem;
  }
  .footer-font2 {
    font-size: 1.59rem;
  }
  .cart {
    position: fixed;
    bottom: 20px;
    left: 5px;
  }
}
