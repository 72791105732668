* {
  box-sizing: border-box !important;
}
body {
  overflow-y: scroll !important;
}
.row,
.container-fluid {
  margin: 0 !important;
}
.container-fluid {
  padding: 0 !important;
}
.upper {
  text-transform: uppercase;
}
.row {
  width: 100% !important;
}
@font-face {
  font-family: Roboto-Black;

  src: url(fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: Roboto-Light;

  src: url(fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Bold;

  src: url(fonts/Roboto-Bold.ttf);
}
.Bold {
  font-family: Roboto-Black;
}
.Lite {
  font-family: Roboto-Light;
}

.IconsMineDisplaySize {
  width: 100% !important;
  height: auto !important;
}
