.display {
  background: white;
  margin-top: 200px;
}
.icon-img {
  width: 32px !important;
  height: 32px !important;
}
.editButton {
  border-radius: 40px;
  border: none;
  width: 100%;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
}
@media screen and (min-width: 570px) and (max-width: 768px) {
}
@media screen and (max-width: 570px) {
}
