.inputs {
  width: 70%;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.inputs-half {
  width: 70%;

  background: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.inputs-half1 {
  width: 70%;

  background: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.sellect {
  width: 70%;
  background: rgba(255, 255, 255, 0);
  border: none;
}
.removeButton {
  border: none;
  background: none;
}
.cart-hidden {
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  background-size: auto 100% !important;
  background-position: 250% 50% !important;
  display: block;
  height: 100% !important;
  overflow: hidden;
}
.cart-hidden2 {
  background-attachment: fixed;
  background-repeat: no-repeat !important;
  background-size: auto 100% !important;
  background-position: 250% 50% !important;
  display: block;
  height: 100% !important;
  overflow: hidden;
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .cart-hidden {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: 800% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
  .cart-hidden2 {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: 600% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .Order-font1 {
    font-size: 1.7rem !important;
  }
  .Order-font2 {
    font-size: 1.1rem !important;
  }
  .cart-hidden {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: -150% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
  .cart-hidden2 {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: -170% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
}
@media screen and (min-width: 570px) and (max-width: 768px) {
  .Order-font1 {
    font-size: 1.7rem !important;
  }
  .Order-font2 {
    font-size: 1.1rem !important;
  }
  .cart-hidden {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: -550% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
  .cart-hidden2 {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: -30% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
}
@media screen and (max-width: 570px) {
  .inputs {
    width: 100%;
    background: rgba(255, 255, 255, 0);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  .inputs-half {
    width: 47.5%;
    margin-left: 2.5%;
    background: rgba(255, 255, 255, 0);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  .inputs-half1 {
    width: 47.5%;
    margin-right: 2.5%;
    background: rgba(255, 255, 255, 0);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
  .sellect {
    width: 100%;
    background: rgba(255, 255, 255, 0);
    border: none;
  }
  .Order-font1 {
    font-size: 1.9rem !important;
  }
  .Order-font2 {
    font-size: 1.1rem !important;
  }
  .cart-hidden2 {
    background-attachment: fixed;
    background-repeat: no-repeat !important;
    background-size: auto 100% !important;
    background-position: -10% 50% !important;
    display: block;
    height: 100% !important;
    overflow: hidden;
  }
}
